<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12>
        <v-card>
          <v-layout wrap pa-5>
            <v-flex xs12 md6>
              <v-layout wrap>
                <v-flex xs12 md4>
                  <span class="sbold">Division:</span> &nbsp;
                </v-flex>

                <v-flex xs12 md8>
                  <span class="regular" v-if="storage.divisionId">{{
                    storage.divisionId.divisionName
                  }}</span>
                </v-flex>

                <v-flex xs12 md4>
                  <span class="sbold">Range:</span> &nbsp;
                 
                </v-flex>
                <v-flex xs12 md8>
                      <span class="regular" v-if="storage.rangeId">{{ storage.rangeId.rangeName }}</span>
                </v-flex>
                <v-flex xs12 md4>
                  <span class="sbold">Beat:</span> &nbsp;
                 
                </v-flex>
                  <v-flex xs12 md8>
                    <span class="regular" v-if="storage.beatId">{{ storage.beatId.beatName }}</span>
                </v-flex>

                <v-flex xs12 md4 style="broder-bottom: 3px solid black">
                  <span class="sbold">Compartment:</span> &nbsp;
            
                </v-flex>
                 <v-flex xs12 md8>
                         <span>{{ storage.compartment }} </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md6 align-self-center>
              <v-card class="" elevation="4" style="background-color: grey">
                <v-layout wrap style="color: white" v-if="storage.userId">
                  <v-flex xs12 px-2 pt-1 >
                    <span class="sbold">Name:</span> &nbsp;
                    <span class="regular"
                      >{{ storage.userId.firstName }} &nbsp;{{
                        storage.userId.lastName
                      }}</span
                    >
                  </v-flex>
                  <v-flex xs12 px-2>
                    <span class="sbold">Phone:</span> &nbsp;
                    <span class="regular">{{ storage.userId.phone }}</span>
                  </v-flex>
                  <v-flex xs12 px-2 pb-1>
                    <span class="sbold">Email:</span> &nbsp;
                    <span class="regular">{{ storage.userId.email }} </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <v-layout>
      <v-flex xs12 pt-3>
        <span class="bold" style="text-transform:uppercase"> <h3>Participents</h3></span>
      </v-flex>
    </v-layout> -->
    <!-- <v-layout wrap>
      <v-flex xs12>
        <v-data-table
          style="cursor: pointer"
          :headers="headers"
          :items="participents"
          class="elevation-1"
          hide-default-footer
        
        >
          <template v-slot:item.create_date="{ item }">
            <span>{{ new Date(item.create_date).toLocaleString() }}</span>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout> -->
    <!-- <v-layout wrap>
      <v-flex xs12>
        <v-card>
          <v-layout wrap pa-5>
            <v-flex md2 lg3 v-for="(item, i) in participents" :key="i">
              <v-layout wrap pa-1>
                <v-flex xs12>
                  <span class="sbold">Name:</span> &nbsp;
                  <span class="regular">{{ item.name }}</span>
                </v-flex>
                <v-flex xs12>
                  <span class="sbold">Designation:</span> &nbsp;
                  <span class="regular">{{ item.designation }}</span>
                </v-flex>
                <v-flex xs12>
                  <span class="sbold">Contact Number:</span> &nbsp;
                  <span class="regular">{{ item.contactNumber }}</span>
                </v-flex>
                <v-flex xs12>
                  <span class="sbold">Area InCharge:</span> &nbsp;
                  <span class="regular">{{ item.areaInCharge }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout> -->
    <v-layout wrap pt-3>
      <v-flex xs12>
        <h3 class="bold" style="text-transform: uppercase">
          Report-{{ storage.photo.length }}
        </h3>
      </v-flex>
      <template>
        <v-flex xs12 pt-1>
          <v-card>
            <v-layout wrap pa-5>
              <v-flex xs12 pt-3 pr-1 align-self-center text-center v-if="storage.photo">
                <v-card outlined v-if="storage.photo.length > 0">
                  <v-card-title>Photos</v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex
                        pa-1
                        xs12
                        md4
                        lg4
                        v-for="(item2, k) in storage.photo"
                        :key="k"
                      >
                        <v-img
                          :src="mediaURL + item2"
                          @click="(imageDialog = true), (dialogImage = item2)"
                          style="cursor: pointer"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color=" #FDAC53"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                <span v-else class="py-2" style="font-family: pregular">
                  No animal mark uploads....
                </span>
              </v-flex>
              <!-- <v-flex xs12 sm6 pt-3 pl-1>
                 <v-card outlined v-if="item.photo.length > 0">
                   <v-card-title>Snare Photos</v-card-title>
                   <v-card-text>
                  <v-layout wrap>
                    <v-flex
                      xs12
                      md4
                      lg4
                      pa-1
                      v-for="(item3, j) in item.photo"
                      :key="j"
                    >
                      <v-img :src="baseURL + '/file/get/' + item3" @click="imageDialog =true,dialogImage = item3" style="cursor:pointer">
                       <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color=" #FDAC53"
                      ></v-progress-circular>
                    </v-row>
                  </template> </v-img>
                    </v-flex>
                  </v-layout>
                  </v-card-text>
                </v-card>
               <span v-else class=" py-2" style=" font-family:pregular" > No snare photo uploads.... </span>

              </v-flex> -->

              <v-flex xs12 md3 pt-3>
                <span class="sbold">Snare Type </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-3>
                <span class="regular">{{ storage.snareTypes.name }}</span>
              </v-flex>
              <v-flex xs12 md3 pt-1>
                <span class="sbold">Animal Stage </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular">{{ storage.animalStage }}</span>
              </v-flex>

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Animal condition </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular">{{ storage.animalCondition }} (m)</span>
              </v-flex>

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Report Type </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular">{{ storage.reportType }} (m)</span>
              </v-flex>

              <!-- <v-flex xs12 pt-1>
                <span class="sbold">Animal Mark -</span> &nbsp;
                <span class="regular">{{ item.animalMark }}</span>
              </v-flex>
              <v-flex xs12 pt-1>
                <span class="sbold">Forest Type -</span> &nbsp;
                <span class="regular">{{ item.forestType }}</span>
              </v-flex>
              <v-flex xs12 pt-1>
                <span class="sbold">Land Scape -</span> &nbsp;
                <span class="regular">{{ item.landScape }}</span>
              </v-flex>
              <v-flex xs12 pt-1>
                <span class="sbold">Remarks -</span> &nbsp;
                <span class="regular">{{ item.remarks }}</span>
              </v-flex>
               <v-flex xs12 pt-1>
                <span class="sbold"> Targeted Sps -</span> &nbsp;
                <span class="regular">{{ item.targetedSps }}</span>
              </v-flex> -->
            </v-layout>
          </v-card>
        </v-flex>
      </template>
    </v-layout>

    <v-dialog v-model="imageDialog" width="600px">
      <v-card>
        <v-img :src="mediaURL + dialogImage">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color=" #FDAC53"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      imageDialog: false,
      dialogImage: null,
      appLoading: false,
      ServerError: false,
      records: [],
      gmap2: null,
      latt: null,
      lonn: null,
      icons: "",
      photos: [],
      user: [],
      video: null,
      boatDocument: null,
      GroupData: [],
      allData: [],
      participents: [],
      snareReports: [],
      gmap: null,
      headers: [
        { text: "name", value: "name" },
        {
          text: "Designation ",
          align: "start",
          sortable: false,
          value: "designation",
        },
        {
          text: "ContactNumber ",
          align: "start",
          sortable: false,
          value: "contactNumber",
        },
        {
          text: "Area InCharge:",
          align: "start",
          sortable: false,
          value: "areaInCharge",
        },

        // { text: " Not Removed Traps", value: "noOfTrapsNotRemoved" },
      ],
      documentFile1: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/view/report/details",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 25,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allData = response.data.data;
            this.participents = response.data.data.participents;
            this.snareReports = response.data.reports;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.v-data-table,
.v-text-field {
  font-family: pregular;
}
</style>